.appbar-button {
	background-color: transparent;
	border: 2px solid red;
	color: red;
	transition: 0.5s;
}

.appbar-button:hover {
	border: 2px solid #ffffff;
}

.appbar-button-signup {
	font-weight: 700;
	border: 2px solid #38abae;
	transition: 0.3s;
}

.appbar-button-signup:hover {
	background-color: transparent;
}
