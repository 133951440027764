.container-settings {
	border-radius: 5px;
	padding: 80px 64px;
	height: 100vh;
	width: 100%;
}

.settings-balance-account {
	padding: 0 16px 16px 16px;
	width: 100%;
}
