/* :root {
	--primary: #249bb1;
	--secondary: #eb5928;
	--gray: #adadad;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Courier New', Courier, monospace;
	background: linear-gradient(120deg, var(--primary), #8e44ad);
	height: 100vh;
	overflow: hidden;
}

.center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	border-radius: 10px;
	border: 1px solid var(--primary);
}

img {
	margin-top: 20px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 120px;
	height: 120px;
}

.center form {
	padding: 0 40px;
	box-sizing: border-box;
}

form .txt_field {
	position: relative;
	border-bottom: 2px solid var(--gray);
	margin: 30px 0;
	caret-color: var(--primary);
}

.txt_field input {
	width: 100%;
	padding: 0 5px;
	height: 40px;
	font-size: 16px;
	border: none;
	outline: none;
}

.txt_field input:-webkit-autofill,
.txt_field input:-webkit-autofill:hover,
.txt_field input:-webkit-autofill:focus,
.txt_field input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
}

.txt_field label {
	position: absolute;
	top: 50%;
	left: 5px;
	color: var(--gray);
	transform: translateY(-50%);
	font-size: 16px;
	pointer-events: none;
	transition: .5s;
}

.txt_field span::before {
	content: "";
	position: absolute;
	top: 40px;
	left: 0;
	width: 0%;
	height: 2px;
	background: var(--primary);
	transition: .5s;
}

.txt_field input:focus~label,
.txt_field input:valid~label {
	top: -5px;
	color: var(--primary);
}

.txt_field input:focus~span::before,
.txt_field input:valid~span::before {
	width: 100%;
}

.pass {
	margin: -5px 0 20px 5px;
	color: var(--secondary);
	cursor: pointer;
	text-align: center;
	display: inline-block;
}

.pass:hover {
	text-decoration: underline;
}

.submitButton {
	width: 100%;
	height: 50px;
	border: 1px solid;
	background-color: var(--primary);
	border-radius: 100px;
	font-size: 18px;
	color: #e9f4fb;
	font-weight: 700;
	cursor: pointer;
	outline: none;
	margin-bottom: 20px;
}

.submitButton:hover {
	border-color: var(--primary);
	transition: .5s;
}

.toast {
	transition-duration: 0.3s;
	background-color: var(--primary);
	border-radius: 8px;
	width: 30%;
	text-align: center;
	position: absolute;
	bottom: 0;
	margin-bottom: 15px;
	padding: 10px;
} */

:root {
	--login-form-border-radius: 10px;
	--login-form-border-radius-elements: 8px;
	--container-login-background-color: #E3E7EB;
}

.container-login {
	background-color: var(--container-login-background-color);
	height: 100vh;
	padding: 50px;
	width: 100%;
}

.login-form {
	background-color: #ffffff;
	box-shadow: #888888 0px 0px 15px 3px;
	border-radius: var(--login-form-border-radius);
	height: 100%;
	display: flex;
	flex-direction: row;
}

.login-form-left {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.login-form-left-title {
	width: 300px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 20px;
}

.login-form-left-fields {
	display: flex;
	flex-direction: column;
	gap: 15px;
	width: 300px;
}

.login-textfield fieldset {
	border-radius: var(--login-form-border-radius-elements);
}

.login-button {
	border-radius: var(--login-form-border-radius-elements);
}

.login-form-right {
	background-image: url("../img/texting-woman.jpg");
	background-position: 50% 0;
	background-size: cover;
	border-bottom-right-radius: var(--login-form-border-radius);
	border-top-right-radius: var(--login-form-border-radius);
	background-repeat: no-repeat;
	width: 100%;
}

.login-form-right-container {
	text-align: center;
	position: relative;
	top: 90%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	width: 80%;
	border: 1px solid #ffffff;
	border-radius: var(--login-form-border-radius-elements);

	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(5px);
}

.signup-form-right {
	background-image: url("../img/texting-woman2.jpg");
	background-position: 50% 0;
	background-size: cover;
	border-bottom-right-radius: var(--login-form-border-radius);
	border-top-right-radius: var(--login-form-border-radius);
	background-repeat: no-repeat;
	width: 100%;
}