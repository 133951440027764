.stats-filter {
	display: flex;
	flex-direction: column;
	gap: 25px;
	width: 25%;
	border-radius: 8px;
	box-shadow: #888888 0px 0px 5px 3px;
	padding: 20px;
	min-width: 250px;
	min-height: 275px;
}

.stats-graphics {
	display: flex;
	flex-direction: column;
	gap: 15px;
	width: 100%;
	border-radius: 8px;
	box-shadow: #888888 0px 0px 5px 3px;
	padding: 20px;
}

.stats-graphics-title {
	width: 100%;
}

.stats-graphics-chart {
	height: 275px;
}
