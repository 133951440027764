* {
	box-sizing: border-box;
}

.container {
  display: flex;
  height: 100vh;
  left: 0;
  padding-top: 75px;
  overflow-y: scroll;
  padding-left: 50px;
  padding-right: 50px;
  position: absolute;
  right: 0;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .container {
    padding-top: 60px;
  }
}

.container-right {
  width: 100%;
  margin-left: 15px;
}

.container-dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inbox-table {
  margin-top: 15px;
}

.chats {
  width: 100%;
  padding: 0 15px;
  color: #fff;
  position: relative;
  font-size: 0.9em;
  overflow-y: scroll;
  height: 400px;
  border-bottom: 1px solid #1976D2;
}

.client-chat {
  width: fit-content;
  max-width: 60%;
  word-break: break-all;
  background-color: #4f5d73;
  padding: 7px 10px;
  border-radius: 10px 10px 10px 0;
  margin: 5px auto 5px 0;
  font-size: 16px;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.my-chat {
  width: fit-content;
  max-width: 60%;
  word-break: break-all;
  background-color: #1976D2;
  padding: 7px 10px;
  border-radius: 10px 10px 0 10px;
  margin: 5px 0 5px auto;
  font-size: 16px;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.my-chat-datetime {
  font-size: 12px;
  font-weight: lighter;
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 4px;
  color: rgb(255, 255, 255);
}

.client-datetime {
  font-size: 12px;
  font-weight: lighter;
  width: 100%;
  display: flex;
  justify-content: start;
  margin-top: 4px;
  color: rgb(255, 255, 255);
}

.chat-image {
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  max-height: 200px;
}

.my-chat>a {
  position: relative;
  background-color: #000;
  width: fit-content;
}

.char-count {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.star {
  background-color: #ffffff;
  border-radius: 100%;
  color: #1976D2;
  transition: 0.3s;
}

.star:hover {
  background-color: #1976D2;
  color: #ffffff;
  transition: 0.3s;
}